import { Analytics } from '@easy-expense/analytics-client';
import { signInGuest } from '@easy-expense/auth-client';
import { useUserSettingsStore } from '@easy-expense/data-firestore-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import _ from 'lodash';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../components/Button.components';
import { auth } from '../../firebase/app';
import { OnboardingState } from '../../pages/ReceiptScanningOnboarding/ReceiptScannerOnboarding';
import LoadingSpinner from '../LoadingSpinner.component';

const DEFAULT_WIDTH = 600;

export type OnboardingStep = {
  continueText: string;
  stepKey: string;
  component: React.FC;
  onContinue?: () => Promise<boolean>;
  canContinue: boolean;
  showContinue?: boolean;
  fullWidth?: boolean;
  loadingText?: string;
};

export const OnboardingFlow: React.FC<{
  onboardingSteps: OnboardingStep[];
  onboardingState: OnboardingState;
  setOnboardingState: (onboardingState: OnboardingState) => void;
  analyticsKey: string;
}> = ({ onboardingSteps, onboardingState, setOnboardingState, analyticsKey }) => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [authenticatedUser, loadingAuthenticatedUser] = useAuthState(auth);

  const currentStep = onboardingSteps[onboardingState.currentStepIndex];

  const shouldShowContinue = currentStep?.showContinue === undefined || currentStep?.showContinue;

  const userData = useUserSettingsStore((state) => state.userData);

  const [isTransitioning, setIsTransitioning] = React.useState(false);

  React.useEffect(() => {
    Analytics.track('screen_change', {
      currentRouteName: 'WOB_' + analyticsKey + '_' + currentStep?.stepKey,
    });
  }, [currentStep?.stepKey]);

  const goToNextStep = async () => {
    setLoading(true);
    let goContinue = true;
    if (currentStep?.onContinue && typeof currentStep.onContinue === 'function') {
      goContinue = await currentStep.onContinue();
    }

    setLoading(false);
    if (goContinue) {
      setIsTransitioning(true);
      const newStepIndex = onboardingState.currentStepIndex + 1;

      setOnboardingState({
        ...onboardingState,
        currentStepIndex: newStepIndex,
      });
    }
  };

  const goToPrevStep = () => {
    const newStepIndex = onboardingState.currentStepIndex - 1;
    setOnboardingState({
      ...onboardingState,
      currentStepIndex: newStepIndex,
    });
  };

  React.useEffect(() => {
    if (!authenticatedUser && !loadingAuthenticatedUser && onboardingState.currentStepIndex !== 0) {
      signInGuest();
    } else if (
      authenticatedUser &&
      !authenticatedUser.isAnonymous &&
      onboardingState.currentStepIndex === 0 &&
      userData?.settings.appOnboardingState === 'finished'
    ) {
      navigate('/transactions');
    }
  }, [authenticatedUser, onboardingState.currentStepIndex, loadingAuthenticatedUser]);

  React.useEffect(() => {
    if (isTransitioning) {
      setIsTransitioning(false);
    }
  }, [isTransitioning]);

  return (
    <Layout.Column
      style={{ width: '100vw', height: '100dvh', minWidth: 350 }}
      bg="brandPrimaryXLight"
    >
      <Layout.Column align grow style={{ overflow: 'scroll' }}>
        <Layout.Column grow style={{ width: '100%' }} align>
          <Layout.Row style={{ maxWidth: DEFAULT_WIDTH, width: '100%' }}>
            <Spacer.Horizontal size={12} />
            {onboardingSteps.slice(1, onboardingSteps.length).map((_, index: number) => {
              if (onboardingState.currentStepIndex === 0) {
                return null;
              }
              return (
                <Layout.Row grow py={20}>
                  <Layout.Column
                    bg={index > onboardingState.currentStepIndex - 1 ? 'grayLight' : 'black'}
                    grow
                    style={{ height: 6 }}
                    radius={100}
                  />
                  <Spacer.Horizontal size={12} />
                </Layout.Row>
              );
            })}
          </Layout.Row>

          <Spacer.Vertical size={12} />

          <Layout.Column
            grow
            px
            style={{ maxWidth: currentStep?.fullWidth ? undefined : DEFAULT_WIDTH, width: '100%' }}
          >
            {loading ? (
              <Layout.Row
                center
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 100,
                }}
                bg="brandPrimaryXLight"
              >
                <Layout.Column grow center>
                  <LoadingSpinner />
                  {currentStep?.loadingText ? (
                    <>
                      <Spacer.Vertical size={16} />
                      <OpenSans.Primary>{currentStep.loadingText}</OpenSans.Primary>
                    </>
                  ) : null}
                </Layout.Column>
              </Layout.Row>
            ) : null}

            <Layout.Column grow>
              <Layout.Column
                key={onboardingState.currentStepIndex}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: isTransitioning ? 700 : 0,
                  right: isTransitioning ? -700 : 0,
                  bottom: 0,
                  opacity: isTransitioning ? 0 : 1,
                  transition: 'opacity .5s ease-in, left 0.3s ease-out, right 0.3s ease-out',
                }}
              >
                {currentStep?.component({})}
              </Layout.Column>
            </Layout.Column>
          </Layout.Column>
        </Layout.Column>
      </Layout.Column>

      <Layout.Row
        style={{
          borderTop: `2px solid ${theme.colors.grayXLight}`,
        }}
        px
        py={24}
        justify
        bg="white"
      >
        <Layout.Row grow style={{ maxWidth: DEFAULT_WIDTH }} px={24}>
          {onboardingState.currentStepIndex > 1 ? (
            <Layout.PressableColumn
              center
              px={24}
              py
              bg="white"
              radius
              border={[1, 'solid', 'gray']}
              onClick={goToPrevStep}
            >
              <Icon name="arrow-back" size={24} color={theme.colors.primary} />
            </Layout.PressableColumn>
          ) : null}

          <Spacer.Horizontal size={12} />
          {shouldShowContinue ? (
            <Layout.Column grow>
              <Button.Primary
                center
                variant="bold"
                content={currentStep?.continueText}
                active={currentStep?.canContinue}
                onClick={goToNextStep}
              />
            </Layout.Column>
          ) : null}
        </Layout.Row>
      </Layout.Row>
    </Layout.Column>
  );
};
