import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { SHADOW_STYLE } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { PROVINCES } from '@easy-expense/utils-shared';
import { useState, useEffect, useMemo } from 'react';
import CountryPicker, {
  Country,
  FlagType,
  getAllCountries,
} from 'react-native-country-picker-modal';

import { Survey } from '../../components/OnboardingFlow/Survey/Survey';
import { useUserLocation } from '../../hooks/useUserLocation';

const ProvinceSelector: React.FC<{
  onSelect: (options: string[]) => void;
  selectedOptions: string[];
}> = ({ onSelect, selectedOptions }) => {
  return (
    <Survey
      header={''}
      text={''}
      options={PROVINCES.map((province) => {
        return {
          title: province.name,
        };
      })}
      multiSelect={false}
      onOptionSelected={(options: string[]) => onSelect(options)}
      selectedOptions={selectedOptions}
    />
  );
};

const DEFAULT_COUNTRY: Country = {
  callingCode: ['1'],
  cca2: 'US',
  currency: ['USD'],
  flag: 'flag-us',
  name: 'United States',
  region: 'Americas',
  subregion: 'North America',
};

export const CountrySelector = ({
  province,
  setCountryCode,
  setProvince,
}: {
  province?: string;
  setProvince: (name: string) => void;
  setCountryCode: (code: string) => void;
}) => {
  const location = useUserLocation();
  const [country, setCountry] = useState<Country>(DEFAULT_COUNTRY);
  const [showCountryPicker, setShowCountryPicker] = useState(false);

  const [countryList, setCountryList] = useState<Country[]>([]);

  useEffect(() => {
    getAllCountries(FlagType.EMOJI).then((countries) => {
      setCountryList(countries);
    });
  }, []);

  useEffect(() => {
    const locatedCountry = countryList.find((country) => country.cca2 === location?.cca2);
    if (locatedCountry) {
      const { callingCode, name, cca2, region, flag, subregion, currency } = locatedCountry;
      if (cca2 === 'CA' && location?.state) {
        setProvince(location.state);
      }
      setCountry({
        callingCode,
        cca2,
        name,
        region,
        flag,
        subregion,
        currency,
      });
    }
  }, [location]);

  useEffect(() => {
    setCountryCode(country.cca2);
  }, [country]);

  const isCanada = useMemo(() => {
    return country.cca2 === 'CA';
  }, [country]);

  const selectProvince = (p: string) => {
    const province = PROVINCES.find((pro) => pro.name === p);
    if (province?.name) {
      setProvince(province.name);
    }
  };

  return (
    <Layout.Column>
      <OpenSans.Primary weight="bold-700" size={24}>
        {getTranslation('What country are you in?')}
      </OpenSans.Primary>
      <Spacer.Vertical size={8} />
      <OpenSans.Secondary size={'s-16'}>
        {getTranslation('This information is used to help us maximize your tax savings.')}
      </OpenSans.Secondary>

      <Spacer.Vertical size={36} />
      <Layout.PressableRow
        align
        onClick={() => setShowCountryPicker(true)}
        px
        py={8}
        border={[2, 'solid', 'primary']}
        radius
        grow
        bg="navHeaderBackground"
        style={SHADOW_STYLE}
      >
        <CountryPicker
          onSelect={setCountry}
          onClose={() => setShowCountryPicker(false)}
          countryCode={country.cca2}
          visible={showCountryPicker}
        />
        <OpenSans.Primary>{country.name}</OpenSans.Primary>
        <Spacer.Flex />
        <Icon name="chevron-down" size={28} iconColor="brandPrimary" />
      </Layout.PressableRow>
      {isCanada ? (
        <ProvinceSelector
          onSelect={(p: string[]) => {
            if (p[0]) {
              selectProvince(p[0]);
            }
          }}
          selectedOptions={province ? [province] : []}
        />
      ) : null}
    </Layout.Column>
  );
};
