import { Analytics } from '@easy-expense/analytics-client';
import { currentUser, sendTrialExpiryEmail, signUp } from '@easy-expense/auth-client';
import {
  useCurrentWorkspace,
  useUserSettings,
  useWorkspaceSettings,
} from '@easy-expense/data-firestore-client';
import { WebEnv } from '@easy-expense/env-mobile';
import { getTranslation } from '@easy-expense/intl-client';
import { MCCIndustries, PROVINCES } from '@easy-expense/utils-shared';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import { Environments, initializePaddle, Paddle as PaddleInstance } from '@paddle/paddle-js';
import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

import { CountrySelector } from './CountrySelector';
import { OnboardingExpense, ReceiptScanner } from './ReceiptScanner';
import { Welcome } from './Welcome';
import { PaddleCheckout } from '../../components/OnboardingFlow/Checkout/PaddleCheckout';
import { OnboardingFlow, OnboardingStep } from '../../components/OnboardingFlow/OnboardingFlow';
import { Signup } from '../../components/OnboardingFlow/Signup/Signup';
import { SearchSurvey } from '../../components/OnboardingFlow/Survey/SearchSurvey';
import { Survey, SurveyOption } from '../../components/OnboardingFlow/Survey/Survey';
import { EmailSchema } from '../../components/SignUp.form';
import { auth } from '../../firebase/app';
import {
  paddleBusinessItemsProd,
  paddleBusinessItemsSandbox,
  paddleClientTokenProd,
  paddleClientTokenSandbox,
} from '../../lib/Paddle';
import { createWorkspace } from '../../lib/createWorkspace';

const ReceiptTypeSurvey: React.FC<{
  onSelect: (options: string[]) => void;
  selectedOptions: string[];
}> = ({ onSelect, selectedOptions }) => {
  return (
    <Survey
      header={getTranslation(`What types of receipts do you need to scan?`)}
      text={'Easy Expense can scan all types of receipts!'}
      options={[
        {
          title: getTranslation('Paper Receipts'),
          success: getTranslation(
            'Great! Our mobile scanner is perfect for scanning paper receipts on the go.',
          ),
        },
        {
          title: getTranslation('Email/ Digital Receipts'),
          success: getTranslation('Awesome! Email receipts can be uploaded by forwarding.'),
        },
        {
          title: getTranslation('Both Paper and Email'),
          success: getTranslation(
            'Perfect! Easy Expense can automatically scan both paper and email receipts.',
          ),
        },
      ]}
      multiSelect={false}
      onOptionSelected={(options: string[]) => onSelect(options)}
      selectedOptions={selectedOptions}
    />
  );
};

const IndustrySurvey: React.FC<{
  onSelect: (options: string[]) => void;
  selectedOptions: string[];
}> = ({ onSelect, selectedOptions }) => {
  const options: { groupHeader: string; groupOptions: SurveyOption[] }[] = MCCIndustries.map(
    (group) => {
      return {
        groupHeader: group.industry_group,
        groupOptions: group.data.map((industry) => {
          return {
            title: industry.industry,
            emoji: industry.emoji,
            searchQueries: industry.search_queries,
          };
        }),
      };
    },
  );

  return (
    <SearchSurvey
      header={getTranslation(`What industry do you work in?`)}
      text={'Select any industries you work in so we can customize your deductions!'}
      options={options}
      multiSelect
      onOptionSelected={(options: string[]) => onSelect(options)}
      selectedOptions={selectedOptions}
    />
  );
};

const TeamSurvey: React.FC<{
  setTeamSurveyOptions: (options: string[]) => void;
  selectedOptions: string[];
}> = ({ setTeamSurveyOptions, selectedOptions }) => {
  return (
    <Survey
      header={getTranslation(`Do you need to track other people's receipts?`)}
      text={'Work alone or with your team!'}
      options={[
        {
          title: getTranslation('Just Me'),
          subtitle: getTranslation('Track your transactions alone'),
        },
        {
          title: getTranslation('Team Tracking'),
          subtitle: getTranslation('Invite your employees and get all your data in one place'),
          success: getTranslation('Perfect! Employees love how simple Easy Expense is to use.'),
        },
      ]}
      multiSelect={false}
      onOptionSelected={(options: string[]) => setTeamSurveyOptions(options)}
      selectedOptions={selectedOptions}
    />
  );
};

const UsageSurvey: React.FC<{
  setUsageOptions: (options: string[]) => void;
  selectedOptions: string[];
}> = ({ setUsageOptions, selectedOptions }) => {
  return (
    <Survey
      header={getTranslation(`Why do you need to scan receipts?`)}
      text={'Select all that apply so we can ensure the best experience for you!'}
      options={[
        {
          title: getTranslation('Tax Deductions'),
          subtitle: getTranslation('Maximize your tax savings'),
          success: getTranslation(
            'Perfect! Easy Expense has already helped users find over $500M in tax deductions.',
          ),
        },
        {
          title: getTranslation('Business Bookkeeping'),
          subtitle: getTranslation('Match receipts with bank statements'),
          success: getTranslation(
            'Perfect! Easy Expense saves 100s of hours per year by automatically reconciling receipts and bank statements.',
          ),
        },
        {
          title: getTranslation('Submit Expense Report'),
          subtitle: getTranslation('Submit PDF reports and get reimbursed'),
          success: getTranslation(
            'Perfect! Generate professional PDF reports in seconds to get reimbursed faster.',
          ),
        },
        {
          title: getTranslation('Personal Budget'),
          subtitle: getTranslation('Personal budgeting'),
        },
      ]}
      multiSelect={true}
      onOptionSelected={(options: string[]) => setUsageOptions(options)}
      selectedOptions={selectedOptions}
    />
  );
};

export type OnboardingState = {
  currentStepIndex: number;
  teamSurveyOptions: string[];
  usageOptions: string[];
  industries: string[];
  typeOptions: string[];
  trialStarted: boolean;
  scannedReceipt?: OnboardingExpense;
  email?: string;
  emailError?: string;
  password?: string;
  workspaceID?: string;
  countryCode?: string;
  provinceName?: string;
};

const emptyOnboardingState: OnboardingState = {
  currentStepIndex: 0,
  teamSurveyOptions: [],
  usageOptions: [],
  typeOptions: [],
  industries: [],
  trialStarted: false,
};

export const ReceiptScanningOnboarding: React.FC = () => {
  const [onboardingState, setOnboardingState] =
    React.useState<OnboardingState>(emptyOnboardingState);
  const { updateWorkspaceSetting } = useWorkspaceSettings();
  const currentWorkspace = useCurrentWorkspace();
  const { updateUserSetting } = useUserSettings();
  const navigate = useNavigate();

  const [user] = useAuthState(auth);

  const userCompletedSignUp = React.useMemo(() => {
    return !user?.isAnonymous;
  }, [user?.isAnonymous]);

  const paddleRef = React.useRef<PaddleInstance | null>(null);
  const [paddleItemsPricings, setPaddleItemsPricings] = React.useState(paddleBusinessItemsProd);

  React.useEffect(() => {
    let paddleEnv: Environments = 'production';
    let paddleClientToken = paddleClientTokenProd;
    if (WebEnv.buildType !== 'production') {
      console.log('using sandbox paddle');
      paddleEnv = 'sandbox';
      paddleClientToken = paddleClientTokenSandbox;
      setPaddleItemsPricings(paddleBusinessItemsSandbox);
    }

    initializePaddle({
      environment: paddleEnv,
      token: paddleClientToken,
      async eventCallback(data) {
        if (data.name === 'checkout.completed') {
          setOnboardingState((prevState) => {
            return { ...prevState, trialStarted: true };
          });
          Analytics.track('trial_started');
          ReactPixel.trackCustom('Trial Started');
          sendTrialExpiryEmail()();
          closePaddleCheckout();
          navigate('/onboarding/get-started');
        }
      },
    })
      .then((paddleInstance: PaddleInstance | undefined) => {
        if (paddleInstance) {
          paddleRef.current = paddleInstance;
        }
      })
      .catch((error) => {
        console.error('Error initializing Paddle: ', error);
      });
  }, []);

  function closePaddleCheckout() {
    if (!paddleRef.current) {
      alert('There was an error closing Paddle checkout');
      return;
    }
    paddleRef.current.Checkout.close();
  }

  function startPaddleCheckout() {
    if (!paddleRef.current) {
      alert('There was an error starting Paddle payment');
      return;
    }

    paddleRef.current.Checkout.open({
      items: [{ priceId: paddleItemsPricings['ANNUAL_7DAYTRIAL_STARTER'] }],
      settings: { showAddDiscounts: false },
      customer: {
        email: onboardingState.email ?? '',
      },
      customData: {
        organizationID: currentWorkspace?.org,
        workspaceID: currentWorkspace?.key,
        userID: currentUser()?.uid ?? '',
      },
    });
  }

  const createWorkspaceAsync = async () => {
    if (currentWorkspace?.key) {
      return;
    }
    await createWorkspace('business', 'Default');

    updateWorkspaceSetting({ onboardingType: 'team' });
  };

  const steps: OnboardingStep[] = [
    {
      continueText: 'Get Started',
      stepKey: 'welcome',
      onContinue: () => {
        ReactPixel.trackCustom('Get Started');
        return Promise.resolve(true);
      },
      canContinue: true,
      component: () => <Welcome />,
    },
    {
      continueText: 'Continue',
      stepKey: 'receipt_type',
      canContinue: onboardingState.typeOptions.length > 0,
      onContinue: () => {
        Analytics.track('receipt_scan_usage', {
          type: onboardingState.typeOptions[0] ?? '',
        });

        if (!currentWorkspace) {
          createWorkspaceAsync();
        }

        return Promise.resolve(true);
      },
      component: () => (
        <ReceiptTypeSurvey
          selectedOptions={onboardingState.typeOptions}
          onSelect={(typeOptions: string[]) => {
            setOnboardingState({ ...onboardingState, typeOptions });
          }}
        />
      ),
    },

    {
      continueText: 'Continue',
      stepKey: 'usage_type',
      canContinue: onboardingState.usageOptions.length > 0,
      onContinue: () => {
        Analytics.track('onboarding_type_question_array', {
          types: onboardingState.usageOptions,
        });

        return Promise.resolve(true);
      },
      component: () => (
        <UsageSurvey
          selectedOptions={onboardingState.usageOptions}
          setUsageOptions={(usageOptions: string[]) => {
            setOnboardingState({ ...onboardingState, usageOptions });
          }}
        />
      ),
    },
    {
      continueText: 'Continue',
      stepKey: 'team',
      canContinue: onboardingState.teamSurveyOptions.length > 0,
      onContinue: () => {
        Analytics.track('team_or_personal', {
          type: onboardingState.teamSurveyOptions[0] ?? '',
        });

        return Promise.resolve(true);
      },
      component: () => (
        <TeamSurvey
          selectedOptions={onboardingState.teamSurveyOptions}
          setTeamSurveyOptions={(teamSurveyOptions: string[]) => {
            setOnboardingState({ ...onboardingState, teamSurveyOptions });
          }}
        />
      ),
    },
    {
      continueText: 'Continue',
      stepKey: 'country',
      canContinue:
        onboardingState.countryCode !== undefined &&
        (onboardingState.countryCode !== 'CA' || onboardingState.provinceName !== undefined),
      onContinue: () => {
        if (onboardingState.countryCode === 'CA' && onboardingState.provinceName) {
          const province = PROVINCES.find((p) => p.name === onboardingState.provinceName);
          if (province) {
            updateWorkspaceSetting({ taxRates: province.taxRates });
          }
        } else {
          updateWorkspaceSetting({ taxRates: [] });
        }
        return Promise.resolve(true);
      },
      component: () => (
        <CountrySelector
          province={onboardingState.provinceName}
          setCountryCode={(countryCode: string) => {
            setOnboardingState({ ...onboardingState, countryCode });
          }}
          setProvince={(provinceName: string) => {
            setOnboardingState({ ...onboardingState, provinceName });
          }}
        />
      ),
    },
    {
      continueText: 'Continue',
      stepKey: 'industry',
      loadingText: getTranslation('Finding your Deductions...'),
      canContinue: onboardingState.industries.length > 0,
      onContinue: async () => {
        Analytics.identify(user?.uid ?? null, {
          industry: onboardingState.industries,
        });
        if (onboardingState.usageOptions.includes('Tax Deductions')) {
          return await new Promise((resolve) => {
            setTimeout(() => {
              resolve(true);
            }, 5000);
          });
        } else {
          return Promise.resolve(true);
        }
      },
      component: () => (
        <IndustrySurvey
          selectedOptions={onboardingState.industries}
          onSelect={(industries: string[]) => {
            setOnboardingState({ ...onboardingState, industries });
          }}
        />
      ),
    },
    {
      continueText: 'Continue',
      stepKey: 'receipt_scan',
      canContinue: onboardingState.scannedReceipt !== undefined,
      onContinue: () => {
        ReactPixel.trackCustom('Scanned Receipt');
        return Promise.resolve(true);
      },
      component: () => (
        <ReceiptScanner
          countryCode={onboardingState.countryCode}
          scannedReceipt={onboardingState.scannedReceipt}
          setScannedReceipt={(scannedReceipt: OnboardingExpense) =>
            setOnboardingState({ ...onboardingState, scannedReceipt })
          }
        />
      ),
      fullWidth: true,
    },
    {
      continueText: userCompletedSignUp ? 'Continue' : 'Sign Up',
      stepKey: 'sign_up',
      onContinue: async () => {
        if (userCompletedSignUp) {
          return true;
        }

        try {
          await signUp(onboardingState.email ?? '', onboardingState.password ?? '');
          ReactPixel.trackCustom('User Signed Up');
          updateUserSetting({ appOnboardingState: 'finished' });
          return true;
        } catch (error: DoNotFixMeIAmAny) {
          if (error.code === 'auth/wrong-password' || error.code === 'auth/email-already-in-use') {
            setOnboardingState({
              ...onboardingState,
              emailError: 'An account already exists with this email.',
            });
          } else {
            setOnboardingState({
              ...onboardingState,
              emailError: 'Cannot log in. Please contact support.',
            });
          }
        }

        return false;
      },
      canContinue:
        userCompletedSignUp ||
        (onboardingState.email !== undefined &&
          onboardingState.password !== undefined &&
          onboardingState.password.length >= 8 &&
          EmailSchema.safeParse(onboardingState.email).success),
      component: () => (
        <Signup
          setEmail={(email: string) => {
            setOnboardingState({ ...onboardingState, email });
          }}
          setPassword={(password: string) => {
            setOnboardingState({ ...onboardingState, password });
          }}
          onboardingState={onboardingState}
        />
      ),
    },
    {
      continueText: onboardingState.trialStarted ? 'Continue' : 'Get Started',
      stepKey: 'trial',
      onContinue: async () => {
        if (onboardingState.trialStarted) {
          return true;
        }

        ReactPixel.trackCustom('Trial Start Clicked');
        startPaddleCheckout();
        return false;
      },
      canContinue: true,
      component: () => <PaddleCheckout />,
    },
  ];

  return (
    <OnboardingFlow
      onboardingState={onboardingState}
      setOnboardingState={setOnboardingState}
      onboardingSteps={steps}
      analyticsKey="receipt_scanner"
    />
  );
};
