import { getCurrentLocationFromConnection } from '@easy-expense/utils-shared';
import React from 'react';

export type UserLocation = {
  cca2: string;
  countryName: string;
  state?: string;
};

export const useUserLocation = () => {
  const [location, setLocation] = React.useState<UserLocation | null>(null);

  React.useEffect(() => {
    getCurrentLocationFromConnection().then((local) => {
      setLocation({
        cca2: local.countryCode ?? 'US',
        countryName: local.countryName ?? 'United States',
        state: local.state,
      });
    });
  }, []);

  return location;
};
